































import GridDefault from './Default.vue';
import {
  getAttributeValue,
  BBRProductAttributes,
  getEventAvailability
} from '@theme/modules/catalog/getters/productGetters';

export default {
  mixins: [GridDefault],
  setup(props) {
    const eventType = getAttributeValue(props.product, BBRProductAttributes.EVENT_TYPE);

    return {
      eventType,
      label: getEventAvailability(props.product),
    }
  },
};
