
















import { defineComponent } from '@nuxtjs/composition-api';
import CartFreeDeliveryInfo from '~/modules/checkout/components/CartFreeDeliveryInfo.vue'
import { USPIcon } from '~/bbrTheme/components';

export default defineComponent({
  name: 'ProductGroupDefault',
  components: {
    CartFreeDeliveryInfo,
    USPIcon,
  },
})
