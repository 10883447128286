// TODO: Move product getters to the `~/modules/catalog/product/getters/productGettersExtended.ts` file
import { Logger } from '~/helpers/logger';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import type { Breadcrumb } from '~/modules/catalog/types';
import { getName, getPrice } from '~/modules/catalog/product/getters/productGetters';
import {
  ConfigurableProduct,
  ConfigurableVariant,
  GrapeVariety,
  GrapeVarietyResult,
  GroupProductVariant,
  GroupProductVariants,
  ProductCustomAttributes,
  ProductInterface,
  ProductLabel,
  SimpleProduct,
  ProductRegionData,
  EventLocation,
  EventLocationDetail,
} from '~/modules/GraphQL/types';
import { ProductStockStatus } from '~/modules/GraphQL/types';
import find from 'lodash-es/find';
import {
  ProductWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import sortBy from 'lodash-es/sortBy';
import isEmpty from 'lodash-es/isEmpty';
import { FamilyType } from '~/bbrTheme/modules/catalog/stores/product';
import { useContext } from '@nuxtjs/composition-api';

export enum BBRProductAttributes {
  ALCOHOL = 'alcohol',
  BODY = 'body',
  BOTTLE_ORDER_UNIT = 'bottle_order_unit',
  BOTTLE_VOLUME = 'bottle_volume',
  CASE_ORDER_UNIT = 'case_order_unit',
  COLOUR = 'colour',
  DRINKING_FROM_YEAR = 'drinking_from_year',
  DRINKING_TO_YEAR = 'drinking_to_year',
  DUTIABLE_VOLUME_ML = 'dutiable_volume_ml',
  FAMILY_TYPE = 'family_type',
  MAIN_IMAGE = 'main_image',
  MAIN_INGREDIENTS = 'main_ingredients',
  MATURITY = 'maturity',
  REGION = 'region',
  PROPERTY = 'property',
  SELL_BY_CASE_ONLY = 'sell_by_case_only',
  STYLE_TEMP = 'style_temp',
  SWEETNESS = 'sweetness',
  EVENT_MAX_TICKETS = 'event_max_tickets',
  EVENT_TYPE = 'event_type',
  GRAPE_LIST = 'grape_list',
  GRAPE_FILTERS = 'grape_filters',
  GRAPE_FILTERS_DATA = 'grape_filters_data',
  GRAPE_VARIETIES = 'grape_varieties',
  INTERNAL_PUBLICATION_DATE = 'internal_publication_date',
  INTERNAL_REVIEWER = 'internal_reviewer',
  IS_A_GIFT_CARD = 'is_a_gift_card',
  EN_PRIMEUR = 'en_primeur',
  ROUNDEL_URL_CODE = 'roundel_url_code',
  EVENT_START_DATE = 'event_start_date',
  EVENT_START_TIME = 'event_start_time',
  EVENT_DRESS_CODE = 'event_dress_code',
  EVENT_HOSTS = 'event_hosts',
  EVENT_END_TIME = 'event_end_time',
  EVENT_LOCATION = 'events_location_data',
  TASTING_NOTE = 'tasting_note',
  VINTAGE = 'vintage',
};

export enum BbrListingTypes {
  BBR = 'BBR',
  BBX = 'BBX',
};

export interface ProductGetters {
  getPredominantGrapeData: (product: ProductInterface | null) => GrapeVarietyResult;
  getProductBreadcrumbs: (product: ProductInterface) => Breadcrumb[];
  getProductAttributeByCode: (product: ProductInterface, code: string) => ProductCustomAttributes;
  getSellByCaseOnly: (product: ProductInterface | null) => boolean;
  getRoundelUrlCode: (product: ProductInterface) => string;
  getLabels: (product: ProductInterface, visibleLabels?: number) => ProductLabel[];
  getProductLabels: (product: ProductInterface, visibleLabels?: number) => ProductLabel[];
  getRegionLabel: (product: ProductInterface | null) => string;
  getCaseOrderUnit: (product: ProductInterface | null) => number;
  getFamilyType: (product: ProductInterface | null) => string;
  getFamilyTypeComponentName: (product: ProductWithCommonProductCardProps) => string;
  hasMoreVariants: (product: ConfigurableProduct | null) => boolean;
  getCasesQtyLabel: (product: SimpleProduct | null) => string;
  getProductUid: (product: ProductInterface) => string;
  getListingId: (product: ProductInterface) => number;
  getListingType: (product: ProductInterface) => BbrListingTypes;
  isSameProduct: (currentProduct: ProductInterface, targetProduct: ProductInterface) => boolean;
  isBbrVariantType: (product: SimpleProduct | null) => boolean;
  isBbxVariantType: (product: SimpleProduct | null) => boolean;
  getVariantSelectedOptions: (variant: GroupProductVariant | null) => string[];
  canHaveCaseOrderUnit: (product: ProductInterface) => boolean;
  canHaveExtraBottleOptions: (product: ProductInterface) => boolean;
  canBeSoldAsInBond: (familyType: string) => boolean;
  getFilteredAttributes: (attributes: ProductCustomAttributes[], allowedList: string[]) => ProductCustomAttributes[];
  getFirstConfigurableVariant: (product: ProductWithCommonProductCardProps | null) => ConfigurableVariant | null;
  getCustomPrice: (product: SimpleProduct | null) => CustomPrice;
  getEventMaxTickets: (product: ProductInterface | null) => number;
  getEventAvailability: (product: ProductInterface | null) => string;
  isEvent: (product: ProductInterface | null) => boolean;
  getProductRegionData: (product: ProductInterface | null) => ProductRegionData;
  getEventLocation: (product: ProductInterface | null) => EventLocationDetail[];
  getEventDate: (product: ProductInterface | null) => string;
  getEventTime: (product: ProductInterface | null) => string;
  getDrinkRange: (product: ProductInterface | null) => string;
}

export const getProductBreadcrumbs = (product: ProductInterface): Breadcrumb[] => {
  const breadcrumbs = [];

  product.breadcrumbs.forEach(item => {
    breadcrumbs.push({
      text: item.category_name,
      link: `/${item.category_url_path}`,
    });
  });

  breadcrumbs.push({
    text: getName(product),
    link: '',
  });

  return breadcrumbs;
};

export const getProductAttributeByCode = (
  product: ProductInterface | null, code: string
): ProductCustomAttributes | null => {
  const attribute = product?.attributes_value?.find((attribute: ProductCustomAttributes) => attribute.code === code);

  if (attribute) {
    return attribute;
  }

  return null;
};

export const isGiftCard = (product: ProductInterface | null, code = 'is_a_gift_card'): boolean => {
  const attribute = getProductAttributeByCode(product, code);

  return !!attribute?.value;
};

export const getAttributeValue = (product: ProductInterface | null, code: string): string => {
  const attribute = getProductAttributeByCode(product, code);

  if (attribute && String(attribute.value || '').trim()) {
    return String(attribute.value || '').trim();
  }

  return '';
};

export const getEventMaxTickets = (product: ProductInterface | null): number => {
  const tickets = product?.attributes_value?.find((attribute: ProductCustomAttributes) => {
    return attribute.code === BBRProductAttributes.EVENT_MAX_TICKETS
  }).value;

  if (!product || !tickets) {
    return 0;
  }

  return Number(tickets);
};

export const getPredominantGrapeData = (product: ProductInterface | null): GrapeVarietyResult => {
  const data: GrapeVarietyResult = { value: '', option_code: '' };
  const varieties = getAttributeValue(product, BBRProductAttributes.GRAPE_VARIETIES);

  if (!varieties) {
    return data;
  }

  try {
    const parsedVarieties = JSON.parse(varieties) as GrapeVariety[];
    let grapeVariety = parsedVarieties.find((variety: GrapeVariety) => !!variety.predominant);

    if (!grapeVariety) {
      grapeVariety = parsedVarieties[0];
    }

    if (grapeVariety) {
      const values = getAttributeValues(product, BBRProductAttributes.GRAPE_FILTERS);
      const optionCodes = getAttributeOptionCodes(product, BBRProductAttributes.GRAPE_FILTERS);
      const index = optionCodes.indexOf(grapeVariety.Grape_Variety);

      data.value = values[index] || '';
      data.option_code = optionCodes[index] || '';
    }
  } catch {
    Logger.error(`There was an error parsing the ${BBRProductAttributes.GRAPE_VARIETIES} attribute.`, varieties);
  }

  return data;
};

/**
 * This logic is used for the multi select attributes which returns string of values joined by comma
 * @param product
 * @param code
 */
export const getAttributeValues = (
  product: ProductInterface | null,
  code: string,
): string[] => splitAttributeData(product, code, 'value');

/**
 * This logic is used for the multi select attributes which returns string of values joined by comma
 * @param product
 * @param code
 */
export const getAttributeOptionCodes = (
  product: ProductInterface | null,
  code: string,
): string[] => splitAttributeData(product, code, 'option_code');

export const splitAttributeData = (product: ProductInterface | null, code: string, key: string): string[] => {
  const attribute = getProductAttributeByCode(product, code);
  const value = String(attribute?.[key] || '').trim();

  if (!value) {
    return [];
  }

  return value.split(',').map((item: string) => item.trim());
};

export const getSortedVariants = (product: ConfigurableProduct | null): ConfigurableVariant[] => {
  const variants = product?.variants ?? [];
  const familyType = getFamilyType(product);
  const volume = familyType === FamilyType.Spirits ? 700 : 750;

  variants?.sort((a, b) => {
    const mlA = getProductAttributeByCode(a.product, BBRProductAttributes.DUTIABLE_VOLUME_ML);
    const mlB = getProductAttributeByCode(b.product, BBRProductAttributes.DUTIABLE_VOLUME_ML);

    if (!mlA && !mlB) return 0;
    if (!mlA) return 1;
    if (!mlB) return -1;
    if (Number(mlA.value) === volume && Number(mlB.value) !== volume) return -1;
    if (Number(mlB.value) === volume && Number(mlA.value) !== volume) return 1;

    return Number(mlA.value) - Number(mlB.value);
  });

  return variants;
};

const findCheapest = (variants: ConfigurableVariant[]): ConfigurableVariant | null => {
  const prices = [];
  variants.map((variant: ConfigurableVariant, idx: number) => {
    if (variant?.product?.price_range) {
      const variantPrices = getPrice(variant.product);
      prices.push({
        idx,
        price: variantPrices.regular <= variantPrices.final ? variantPrices.regular : variantPrices.special,
      });
    }
  });
  const sortedPrices = sortBy(prices, (item) => item.price);

  return sortedPrices.length ? variants[sortedPrices[0]?.idx] : null;
}

export const getConfigurableVariant = (
  product: ProductWithCommonProductCardProps,
  selectedFilters
): ProductWithCommonProductCardProps | ConfigurableVariant => {
  const variants = product.variants || [];
  const filteredVariants = variants.filter((variant: ConfigurableVariant) => {
    if (!isEmpty(selectedFilters)) {
      return find(selectedFilters, (filterOptions) => {
        return filterOptions.find(option => {
          return variant.product.attributes_value.find(attribute => {
            return option.id === attribute.code && option.label === attribute.value
          });
        });
      });
    }

    return Number(getAttributeValue(variant.product, BBRProductAttributes.DUTIABLE_VOLUME_ML)) === 750;
  })
  const cheapestVariant = filteredVariants.length ? findCheapest(filteredVariants) : findCheapest(variants);

  return cheapestVariant || product;
}

/**
 * We always can receive maximum one variant and that's the reason why we get the first index
 * @param product
 */
export const getFirstConfigurableVariant = (
  product: ProductWithCommonProductCardProps | null
): ConfigurableVariant | null => {
  if (product.__typename !== 'ConfigurableProduct') {
    return null;
  }

  return product?.variants?.[0] || null;
}

export const getRoundelUrlCode = (product: ProductInterface): string => {
  return product.roundel_url_code || '';
};

export const getSellByCaseOnly = (product: ProductInterface | null): boolean => {
  if (!product || !product.sell_by_case_only) {
    return false;
  }

  return Boolean(product.sell_by_case_only);
};

export const getProductLabels = (product: ProductInterface, visibleLabels = 1): ProductLabel[] => {
  if (!product.product_labels) {
    return [];
  }

  return product.product_labels.slice(0, visibleLabels);
};

export const getLabels = (product: ProductInterface, visibleLabels = 6): ProductLabel[] => {
  if (!product.labels) {
    return [];
  }

  return product.labels.slice(0, visibleLabels);
};

export const getRegionLabel = (product: ProductInterface | null): string => {
  return getAttributeValue(product, BBRProductAttributes.REGION);
};

export const getFamilyType = (product: ProductInterface | null): string => {
  return getAttributeValue(product, BBRProductAttributes.FAMILY_TYPE);
}

export const getCaseOrderUnit = (product: ProductInterface | null): number => {
  const caseOrderUnit = getAttributeValue(product, BBRProductAttributes.CASE_ORDER_UNIT);

  return parseInt(caseOrderUnit, 10) || 1;
}

export const getFamilyTypeComponentName = (product: ProductWithCommonProductCardProps): string => {
  // replace is used to remove spaces since for some families we get names with spaces from Akeneo
  const familyType = getFamilyType(product) as FamilyType || FamilyType.Accessories;

  return familyType.replace(/ /g, '');
};

// Returns 'true' if configurable product have more then one variant. Else return 'false'
export const hasMoreVariants = (product: ConfigurableProduct | null): boolean => {
  if (!product || !product.is_more_variant_available) {
    return false;
  }

  return product.is_more_variant_available;
}

export const getFilteredAttributes = (
  attributes: ProductCustomAttributes[],
  allowedList: string[],
): ProductCustomAttributes[] => {
  return attributes.filter(
    (attribute: ProductCustomAttributes) => allowedList.includes(attribute.code) && !!attribute.value
  ).sort(
    (a: ProductCustomAttributes, b: ProductCustomAttributes) => {
      return allowedList.indexOf(a.code) - allowedList.indexOf(b.code);
    }
  );
}

interface CustomPrice {
  price: number | null;
  pricePerCase: number | null;
  specialPrice: number | null;
  specialPricePerCase: number | null;
}

/**
 * Get the custom price for the `product variant`
 */
export const getCustomPrice = (product: SimpleProduct | null): CustomPrice => {
  let price = 0;
  let pricePerCase = 0;

  // Note: specialPrice is not used in the current implementation and has to be implemented on the backend
  let specialPrice = null;
  let specialPricePerCase = null;

  if (product?.custom_prices?.price_value) {
    price = product.custom_prices.price_value.amount.value;
  }

  if (product?.custom_prices?.price_per_case) {
    pricePerCase = product.custom_prices.price_per_case.amount.value;
  }

  return {
    price,
    pricePerCase,
    specialPrice,
    specialPricePerCase,
  }
}

export const getEventAvailability = (product: ProductInterface | null): string => {
  const { i18n } = useContext();
  const isInStock = product?.stock_status === ProductStockStatus.InStock;

  if (!isInStock) {
    return i18n.t('Sold out') as string;
  }

  return i18n.t('Places available') as string;
}

export const isEvent = (product: ProductInterface | null): boolean => {
  return getFamilyType(product) === FamilyType.Events;
}

export const getStockDataCaseQty = (product: ProductInterface | null): number => {
  if (!product || !product.stock_data || !product.stock_data.case_qty) {
    return 0;
  }

  return Math.floor(product.stock_data.case_qty);
}

export const getStockDataQty = (product: ProductInterface | null): number => {
  if (!product || !product.stock_data || !product.stock_data.qty) {
    return 0;
  }

  return product.stock_data.qty;
}

/**
 * Cases QTY label are displayed based on the following rules:
 * - if `cases_qty > 20` then we need to display - `20+ cases`
 * - if `cases_qty > 1 && cases_qty <= 20` then we need to display - `{qty} cases`
 * - if `cases_qty === 1` then we need to display - `1 case`
 */
export const getCasesQtyLabel = (product: SimpleProduct | null): string => {
  const casesQty = getStockDataCaseQty(product);

  if (casesQty > 20) {
    return '20+ cases';
  }

  if (casesQty > 1 && casesQty <= 20) {
    return `${casesQty} cases`;
  }

  if (casesQty === 1) {
    return '1 case';
  }

  return '';
}

export const getProductUid = (product: ProductInterface | null): string => (
  product?.uid || ''
);

export const getListingId = (product: ProductInterface | null): number => (
  product?.listing_id || 0
);

export const getListingType = (product: ProductInterface | null): BbrListingTypes => (
  (product?.listing_type || BbrListingTypes.BBR) as BbrListingTypes
);

/**
 * This function is used to check if the current product and the target product are the same.
 * This is used because BBX listings have the same SKU and UID as the BBR configurable variant.
 * @param currentProduct
 * @param targetProduct
 */
export const isSameProduct = (currentProduct: ProductInterface | null, targetProduct: ProductInterface | null) =>
  getProductUid(currentProduct) === getProductUid(targetProduct) &&
  getListingId(currentProduct) === getListingId(targetProduct) &&
  getListingType(currentProduct) === getListingType(targetProduct);

/**
 * Check if the `product variant` listing is of type `BBR`
 */
export const isBbrVariantType = (product: ProductInterface | null): boolean => {
  if (!product || !product.listing_type) {
    return false;
  }

  return product.listing_type === BbrListingTypes.BBR;
}

/**
 * Check if the `product variant` listing is of type `BBX`
 */
export const isBbxVariantType = (product: ProductInterface | null): boolean => {
  if (!product || !product.listing_type) {
    return false;
  }

  return product.listing_type === BbrListingTypes.BBX;
}

export const getVariantSelectedOptions = (variant: GroupProductVariant | null): string[] => {
  const selectedOptions = [];

  if (variant?.attributes) {
    variant.attributes.forEach(attribute => {
      selectedOptions.push(attribute.uid);
    });
  }

  return selectedOptions;
};

export const canBeSoldAsInBond = (familyType: string): boolean => {
  return [
    FamilyType.Wines,
    FamilyType.Spirits,
    FamilyType.OtherDrinks,
    FamilyType.AssortmentMixedCases,
  ].includes(familyType as FamilyType);
};

export const canHaveCaseOrderUnit = (product: ProductInterface): boolean => {
  const familyType = getFamilyType(product);

  return canBeSoldAsInBond(familyType);
};

/**
 * Returns 'true' if family type can has extra bottle option
 * @param product
 */
export const canHaveExtraBottleOptions = (product: ProductInterface): boolean => {
  const familyType = getFamilyType(product) as FamilyType;

  return [
    FamilyType.Wines,
    FamilyType.Spirits,
    FamilyType.OtherDrinks
  ].includes(familyType);
};

/**
 * Extend group of variants with the fake bottle options connected to the real variants
 * @param group
 */
const extendedGroupWithBottleOptions = (group: ConfigurableVariant[]): GroupProductVariant[] => {
  const updatedGroup = [] as GroupProductVariant[];
  const addedPrices = new Set();

  group.forEach((variant: GroupProductVariant) => {
    const customPrice = getCustomPrice(variant.product);

    variant.isBottle = false;
    variant.sellByCaseOnly = getSellByCaseOnly(variant.product);

    if (!addedPrices.has(customPrice.price) && !variant.sellByCaseOnly) {
      updatedGroup.push({
        ...variant,
        isBottle: true,
        product: {
          ...variant.product,
          uid: `${variant.product.uid}-bottle`,
          sku: `${variant.product.sku}-bottle`,
        },
        variants: group.filter(
          (item: GroupProductVariant) => (
            getCustomPrice(item.product).price === customPrice.price && !item.sellByCaseOnly
          ),
        ),
      });

      addedPrices.add(customPrice.price);
    }

    updatedGroup.push(variant);
  });

  return updatedGroup;
};

export const sortGroupByPrice = (group: ConfigurableVariant[]): ConfigurableVariant[] => (
  group.sort((a: ConfigurableVariant, b: ConfigurableVariant) => {
    return getCustomPrice(a.product).price - getCustomPrice(b.product).price;
  })
);

/**
 * Retrieves grouped variants of a configurable product based on bottle information.
 *
 * @param product - The configurable product to retrieve variants from.
 * @param shouldGenerateBottleOptions - The configurable product to retrieve variants from.
 * @returns An object containing grouped variants with extended properties.
 */
export const getGroupedVariantsByBottleInfo = (
  product: ConfigurableProduct | null,
  shouldGenerateBottleOptions = true,
): GroupProductVariants => {
  // Get sorted variants of Configurable Product
  const variants = getSortedVariants(product);

  // Group variants by bottle volume and order unit
  const groupedVariants = groupBy(variants, (variant) => {
    const bottleVolume = getAttributeValue(variant.product, BBRProductAttributes.BOTTLE_VOLUME);
    const bottleOrderUnit = getAttributeValue(variant.product, BBRProductAttributes.BOTTLE_ORDER_UNIT);

    return `${bottleVolume} ${bottleOrderUnit}`;
  });

  // Map over the grouped variants and add the `sellByCaseOnly` property to each variant
  // This is used to determine if the variant is sold by case only or not in the UI
  const groupedVariantsExtended = mapValues(groupedVariants, (group: ConfigurableVariant[]) => {
    group = sortGroupByPrice(group);

    if (shouldGenerateBottleOptions && canHaveExtraBottleOptions(product)) {
      return extendedGroupWithBottleOptions(group);
    }

    return group.map((variant: ConfigurableVariant): GroupProductVariant => {
      return {
        ...variant,
        isBottle: false,
        sellByCaseOnly: getSellByCaseOnly(variant.product),
      };
    });
  });

  return groupedVariantsExtended;
};

/**
 * Retrieves the title for the group variant `bottle` information.
 *
 * @param product - The product object.
 * @returns The title for the group variant `bottle` information.
 */
export const getGroupVariantBottleInfoTitle = (product: ProductInterface | null): string => {
  const bottleVolume = getAttributeValue(product, BBRProductAttributes.BOTTLE_VOLUME);

  if (!bottleVolume) {
    return '';
  }

  return `Bottle - 1 x ${bottleVolume}`;
};

/**
 * Retrieves the title for the group variant `case` information.
 *
 * @param product - The product object.
 * @returns The title for the group variant `case` information.
 */
export const getGroupVariantCaseInfoTitle = (product: ProductInterface | null): string => {
  const caseOrderUnit = getCaseOrderUnit(product);
  const bottleVolume = getAttributeValue(product, BBRProductAttributes.BOTTLE_VOLUME);

  if (!caseOrderUnit || !bottleVolume) {
    return '';
  }

  return `Case - ${caseOrderUnit} x ${bottleVolume}`;
};

/**
 * Retrieves the Region Data Object.
 *
 * @param product - The product object.
 * @returns The ProductRegionData type of object.
 */
export const getProductRegionData = (product: ProductInterface | null): ProductRegionData => {
  return {
    img: product?.region_data?.img || '',
    description: product?.region_data?.description || '',
    url: product?.region_data?.url || '',
    title: product?.region_data?.title || '',
    button_title: product?.region_data?.button_title || '',
  }
}

/**
 * Retrieves Event's starting date
 *
 * @param product - The product object.
 * @returns Starting date string
 */
export const getEventDate = (product: ProductInterface | null): string => {
  if (!product) return '';

  const startDate = getAttributeValue(product, BBRProductAttributes.EVENT_START_DATE);

  if (!startDate) return '';

  let newDate = new Date(startDate);

  return Intl.DateTimeFormat('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(newDate);
}

/**
 * Retrieves Event's location
 *
 * @param product - The product object.
 * @returns Array of formatted location details
 */
export const getEventLocation = (product: ProductInterface | null): EventLocationDetail[] => {
  if (!product) return [];

  const rawLocation = getAttributeValue(product, BBRProductAttributes.EVENT_LOCATION);

  if (!rawLocation) return [];

  try {
    const parsedLocation = JSON.parse(rawLocation) as EventLocation[];

    if (Array.isArray(parsedLocation) && parsedLocation.length > 0) {
      const {
        label = '',
        address1 = '',
        address2 = '',
        postcode = ''
      } = parsedLocation[0];

      return [
        { code: 'label', value: label },
        { code: 'address', value: address1 },
        { code: 'postcode', value: `${address2} ${postcode}`.trim() }
      ];
    }

    return [];
  } catch (error) {
    Logger.error('Error parsing location JSON:', error);

    return [];
  }
}

/**
 * Retrieves Event's time range
 *
 * @param product - The product object.
 * @returns Formatted string based on event start time and event end time
 */
export const getEventTime = (product: ProductInterface | null): string => {
  if (!product) return '';

  const eventStartTime = getAttributeValue(product, BBRProductAttributes.EVENT_START_TIME);
  const eventEndTime = getAttributeValue(product, BBRProductAttributes.EVENT_END_TIME);

  return eventStartTime && eventEndTime ? `(${eventStartTime} - ${eventEndTime})` : '';
}

/**
 * Retrieves Drinking Range Window
 *
 * @param product - The product object.
 * @returns Formatted drinking range string or an empty string if years are missing.
 */
export const getDrinkRange = (product: ProductInterface | null): string => {
  if (!product) return '';

  const drinkingFromYear = getAttributeValue(product, BBRProductAttributes.DRINKING_FROM_YEAR);
  const drinkingToYear = getAttributeValue(product, BBRProductAttributes.DRINKING_TO_YEAR);

  return drinkingFromYear && drinkingToYear ? `(${drinkingFromYear} - ${drinkingToYear})` : '';
}

const productGetters: ProductGetters = {
  getPredominantGrapeData,
  getProductBreadcrumbs,
  getProductAttributeByCode,
  getSellByCaseOnly,
  getRoundelUrlCode,
  getLabels,
  getProductLabels,
  getRegionLabel,
  getCaseOrderUnit,
  getFamilyType,
  getFamilyTypeComponentName,
  hasMoreVariants,
  getCasesQtyLabel,
  getProductUid,
  getListingId,
  getListingType,
  isSameProduct,
  isBbrVariantType,
  isBbxVariantType,
  getVariantSelectedOptions,
  canHaveCaseOrderUnit,
  canHaveExtraBottleOptions,
  canBeSoldAsInBond,
  getFilteredAttributes,
  getFirstConfigurableVariant,
  getCustomPrice,
  getEventMaxTickets,
  getEventAvailability,
  isEvent,
  getProductRegionData,
  getEventLocation,
  getEventDate,
  getEventTime,
  getDrinkRange,
};

export default productGetters;
